export const img_Python = require('../../images/python.png');

export const homeObjOne = {
    id: 'playeras',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Textil',
    headline: 'Playeras',
    description1: 'Material: 100% algodón',
    description2: 'Tallas: CH a 2EG',
    description3: 'Colores: Negro, Marino y Blanco',
    description4: 'Técnica: Serigrafía',
    buttonLabel: 'Ver más',
    imgStart: false,
    img: require("../../images/playeras.png").default,
    alt: 'playera',
    dark: true,
    primary: true,
    darkText: false,
    menu: 'termos'
};

export const homeObjTwo = {
    id: 'termos',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Metal',
    headline: 'Termos',
    description1: 'Material: Acero Inoxidable',
    description2: 'Capacidad: 750 ml',
    description3: 'Color: Negro',
    description4: 'Técnica: Serigrafía',
    buttonLabel: 'Ver más',
    imgStart: true,
    img: require('../../images/termo.png').default,
    alt: 'termo',
    dark: false,
    primary: false,
    darkText: true,
    menu: 'libretas'
};



export const homeObjThree = {
    id: 'libretas',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Poliuretano',
    headline: 'Libretas',
    description1: 'Material: Curpiel',
    description2: 'No. Hojas: 75 (Raya)',
    description3: 'Colores: Azul, Negro y Verde',
    description4: 'Técnica: Serigrafía',
    buttonLabel: 'Ver más',
    imgStart: false,
    img: require("../../images/libretas.png").default,
    alt: 'libreta',
    dark: true,
    primary: true,
    darkText: false,
    menu: 'tazas'
};


export const homeObjFour = {
    id: 'tazas',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Ceramica',
    headline: 'Tazas',
    description1: 'Material: Cerámica',
    description2: 'Capacidad: 325 ml',
    description3: 'Color: Blanco',
    description4: 'Técnica: Serigrafía',
    buttonLabel: 'Ver más',
    imgStart: true,
    img: require('../../images/tazas.png').default,
    alt: 'tazas',
    dark: false,
    primary: false,
    darkText: true,
    menu: ''
};




export const homeObjThreeSSSSS = {
    id: 'signup',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Join our Team',
    headline: 'Three Transactions with zero fees',
    description: 'Three app that allows you to send unlimited transactions without gettin charged any fees.',
    buttonLabel: 'Start Now',
    imgStart: false,
    img: require('../../images/svg1.svg'),
    alt: 'Paper',
    dark: false,
    primary: false,
    darkText: true
};

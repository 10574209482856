import React, { useState } from 'react'

import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import HeroSection from '../components/HeroSection';
import Carousel from '../components/Carousel';
import InfoSection from '../components/InfoSection';
import { diseñosObjOne } from '../components/Carousel/Data';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from '../components/InfoSection/Data';
//import Services from '../components/Services';
import Footer from '../components/Footer';


const Home = () => {

  const [isOpen, setIsOpen ] = useState (false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

    return (
        <>
          <Sidebar isOpen={isOpen} toggle={toggle} />
          <Navbar toggle={toggle} />
          <HeroSection />
          <Carousel {...diseñosObjOne} />
          <InfoSection {...homeObjOne} />
          <InfoSection {...homeObjTwo} />
          { /* <Services /> */ }
          <InfoSection {...homeObjThree} />
          <InfoSection {...homeObjFour} />
          <Footer />
        </>
    )
}

export default Home

import React from 'react'
import SignIn from '../components/Signin'
import ScrollTop from '../components/ScrollTop'

const SigninPage = () => {
    return (
        <div>
            <ScrollTop />
            <SignIn />
        </div>
    )
}

export default SigninPage

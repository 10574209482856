import React from 'react'
import { 
    SidebarContainer, 
    Icon, 
    CloseIcon,
    SidebarWrapper,
    SidebarMenu,
    SidebarLink,
    SideBtnWrap,
    SidebarRoute
    
     } from './SidebarElements';

const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="diseños" onClick={toggle}>Diseños</SidebarLink>
                    <SidebarLink to="playeras" onClick={toggle}>Playeras</SidebarLink>
                    <SidebarLink to="termos" onClick={toggle}>Termos</SidebarLink>
                    <SidebarLink to="libretas" onClick={toggle}>Libretas</SidebarLink>
                    <SidebarLink to="tazas" onClick={toggle}>Tazas</SidebarLink>
                </SidebarMenu>
                <SideBtnWrap>
                    <SidebarRoute to="">Iniciar sesión</SidebarRoute> {/* to="/signin" */}
                </SideBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar

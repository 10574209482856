import styled, { keyframes } from "styled-components";

export const SlideshowContainer = styled.div`
  max-width: 1000px;
  position: relative;
  margin: auto;
`;

//Fade animation
export const fade = keyframes`
  from {
    opacity: 0.4;
  }

  to {
    opacity:1;
  }
`;

export const MySlide = styled.img`
  display: ${(props) => (props.isActive ? "flex" : "none")};
  width: 100%;
  animation: ${fade} 1.5s;
`;

export const Prev = styled.a`
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: Black;
  font-weight: bold;
  font-size: 38px;
  transition: 0.6s ease;
  border-radius: 3px 3px 3px 3px;
  user-select: none;
  &:hover {
    color: White;
    background-color: rgba(0, 0, 0, 0.8);

  }
`;

export const Next = styled.a`
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: Black;
  font-weight: bold;
  font-size: 38px;
  transition: 0.6s ease;
  user-select: none;
  right: 0;
  border-radius: 3px 3px 3px 3px;
  &:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

export const DotContainer = styled.div`
  text-align: center;
`;

export const Dot = styled.span`
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: ${(props) => (props.isActive ? "#717171" : "#bbb")};
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
  &:hover {
    background-color: #717171;
  }
`;


//---------------------------------------------------
//import styled from 'styled-components'

export const InfoContainer = styled.div`
    color: #fff;
    background: ${({ lightBg }) => (lightBg ? '#f9f9f9' : '#010606')}; 

    @media screen and (max-width: 768px) {
        padding: 100px 0;
    };
`;

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 860px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;    
`;

export const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'` )}; 

    @media screen and (max-width: 768px) {        
         grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1' 'col1' 'col2' 'col2'`)};
    };
`;

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;
`;

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col2;
`;

export const TextWrapper = styled.div`
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
`;

export const TopLine = styled.p`
    color: #01bf71;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;    
`;

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#010606')}; 
    
    @media screen and (max-width: 480px) {
        font-size: 32px;
    };
`;

export const Subtitle = styled.p`
    max-width: 440px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 24px;
    color: ${({ darkText }) => (darkText ? '#010606' : '#fff')};
`;

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;    
    margin-top: 35px;
`;

export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;
`;

export const Img = styled.img`
    width: 100%;
    margin: 0 0 50px 0;
    padding-right: 0;
`;
import React from 'react'
import { animateScroll as scroll } from 'react-scroll';

import { 
    FooterContainer, 
    FooterWrap,
    SocialMedia,
    SocialMediaWrap,
    SocialLogo,
    WebsiteRights,
    SocialIcons,
    SocialIconLink
       } from './FooterElements'

import { FaFacebook, FaInstagram, FaYoutube, FaTwitter } from 'react-icons/fa'


const Footer = () => {

    const toggleHome = () => {
        scroll.scrollToTop();
    };
        
    return (
        <>
            <FooterContainer>
                <FooterWrap>

                    <SocialMedia>
                        <SocialMediaWrap>
                            <SocialLogo to='/' onClick={toggleHome}>
                                Developers
                            </SocialLogo>
                            <WebsiteRights>
                                Devs @ {new Date().getFullYear()} All rights reserved
                            </WebsiteRights>

                            <SocialIcons>
                                <SocialIconLink href="/" target="_blank" arial-label="Facebook">
                                    <FaFacebook />
                                </SocialIconLink>

                                <SocialIconLink href="/" target="_blank" arial-label="Facebook">
                                    <FaInstagram />
                                </SocialIconLink>

                                <SocialIconLink href="/" target="_blank" arial-label="Facebook">
                                    <FaYoutube />
                                </SocialIconLink>

                                <SocialIconLink href="/" target="_blank" arial-label="Facebook">
                                    <FaTwitter />
                                </SocialIconLink>

                            </SocialIcons>

                        </SocialMediaWrap>
                    </SocialMedia>
                </FooterWrap>
            </FooterContainer>
        </>
    )
}

export default Footer


export const diseñosObjOne = {
    id: 'diseños',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Diseños',
    headline: 'Tecnologia',
    description1: 'Estampamos el diseño de tu preferencia en:',
    material1: '- Playeras',
    material2: '- Termos',
    material3: '- Libretas',
    material4: '- Tazas',
    buttonLabel: 'Ver productos',
    imgStart: true,
    img: require("../../images/python.png").default,
    alt: 'diseños',
    dark: false,
    primary: false,
    darkText: true,
    menu: 'playeras'
};

import React, { useState } from 'react'
import Video from '../../videos/desarrollo.mp4'

import { 
    HeroContainer, 
    HeroBg, 
    VideoBg, 
    HeroContent, 
    HeroH1, 
    HeroP, 
    HeroBtnWrapper, 
    ArrowForward, 
    ArrowRight } from './HeroElements'

import {Button} from '../ButtonElements'    

const HeroSection = () => {

    const  [ hover, setHover ] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }

    return (
        <HeroContainer id='home'>
            <HeroBg>
                <VideoBg autoPlay loop muted src={Video} type='video/mp4' />
            </HeroBg>
            <HeroContent>
                <HeroH1>Personalizados para Devs</HeroH1>
                <HeroP>
                Te ofrecemos distintos artículos personalizados, cada uno de estos se somete a un proceso
                 de impresión artesanal, garantizando que no haya dos piezas idénticas. A través de la técnica
                  de serigrafía, destacamos la creatividad y habilidad de nuestros artesanos, creando productos
                   únicos y exclusivos, diseñados especialmente para ti.
                </HeroP>
                <HeroBtnWrapper>
                    <Button 
                        to="diseños" 
                        onMouseEnter={onHover} 
                        onMouseLeave={onHover} 
                        primary='true'
                        dark='true'
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}
                    >
                        Ver Diseños {hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection

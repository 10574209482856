import React from 'react'
import { useState } from "react";
import { Button } from '../ButtonElements'

import banner0 from "../../images/GitHub.svg";
import banner1 from "../../images/Java.svg";
import banner2 from "../../images/AngularJS.svg";
import banner3 from "../../images/Git.svg";
import banner4 from "../../images/Python.svg";
import banner5 from "../../images/Oracle.svg";
import banner6 from "../../images/GitLab.svg";
import banner7 from "../../images/Spring.svg";
import banner8 from "../../images/DevOps.svg";
import banner9 from "../../images/Docker.svg";
import banner10 from "../../images/Kubernetes.svg";
import banner11 from "../../images/Jenkins.svg";
import banner12 from "../../images/Html.svg";
import banner13 from "../../images/CSS3.svg";
import banner14 from "../../images/JavaScript.svg";
import banner15 from "../../images/NodeJS.svg";
import banner16 from "../../images/Linux.svg";
import banner17 from "../../images/Firefox.svg";
import banner18 from "../../images/RedHat.svg";
import banner19 from "../../images/Android.svg";
import banner20 from "../../images/Debian.svg";
import banner21 from "../../images/GitKraken.svg";
import banner22 from "../../images/RaspberryPi.svg";
import banner23 from "../../images/Kotlin.svg";
import banner24 from "../../images/Google.svg";

import {
    SlideshowContainer,
    MySlide,
    Prev,
    Next,
    DotContainer,
    Dot,

    InfoContainer, 
    InfoWrapper, 
    InfoRow, 
    Column1, 
    TextWrapper, 
    TopLine, 
    Heading, 
    Subtitle, 
    BtnWrap, 
    Column2,
    ImgWrap,
    Img
} from './CarouselElements';



const Carousel = ({
  id,
  lightBg,
  lightText,    
  topLine,
  headline,
  description1,
  material1,
  material2,
  material3,
  material4,
  buttonLabel,    
  imgStart,
  img,
  alt,    
  dark,
  dark2,
  primary,
  darkText,
  menu,
}) => {

    const slides = [
        banner0, 
        banner1, 
        banner2, 
        banner3,
        banner4,
        banner5,
        banner6,
        banner7,
        banner8,
        banner9,
        banner10,
        banner11,
        banner12,
        banner13,
        banner14,
        banner15,
        banner16,
        banner17,
        banner18,
        banner19,
        banner20,
        banner21,
        banner22,
        banner23,
        banner24, 
    ];
    const [counter, setCounter] = useState(0);

    const handleNext = () => {
        if (counter >= slides.length - 1) {
        setCounter(0);
        } else {
        setCounter(counter + 1);
        }
    };

    const handlePrev = () => {
        if (counter <= 0) {
        setCounter(slides.length - 1);
        } else {
        setCounter(counter - 1);
        }
    };
  
    return (

      <>
      <InfoContainer lightBg={lightBg} id={id}>
          <InfoWrapper>
              <InfoRow imgStart={imgStart}>

                  <Column1>
                      <TextWrapper>
                          <TopLine>{topLine}</TopLine>
                          <Heading lightText={lightText}> {headline}</Heading>
                          <Subtitle darkText={darkText}> {description1}</Subtitle>
                          <Subtitle darkText={darkText}> {material1}</Subtitle>
                          <Subtitle darkText={darkText}> {material2}</Subtitle>
                          <Subtitle darkText={darkText}> {material3}</Subtitle>
                          <Subtitle darkText={darkText}> {material4}</Subtitle>

                          <BtnWrap>
                              <Button
                              to={menu}
                              smooth={true}
                              duration={500}
                              spy={true}
                              exact='true'
                              offset={-80}
                              primary={primary ? 1 : 0}
                              dark={dark ? 1 : 0}
                              dark2={dark2 ? 1 : 0}
                              >
                              {buttonLabel}
                              </Button>
                          </BtnWrap>
                      </TextWrapper>
                  </Column1>

                  <Column2>
                      <ImgWrap>
                          {/*  <Img src={img} alt={alt} /> */}
                          <div className="App">

                            <SlideshowContainer>
                              {slides.map((e, i) => (
                                <MySlide src={e} key={i} isActive={i === counter} />
                              ))}
                              <Prev onClick={handlePrev}>&#10094;</Prev>
                              <Next onClick={handleNext}>&#10095;</Next>
                            </SlideshowContainer>
                      
                            <DotContainer>
                              {slides.map((e, i) => (
                                <Dot
                                  isActive={i === counter}
                                  key={i}
                                  onClick={() => setCounter(i)}
                                ></Dot>
                              ))}
                            </DotContainer>

                        </div>

                      </ImgWrap>
                  </Column2>
                  
              </InfoRow>
          </InfoWrapper>
      </InfoContainer>
  </>

     
        
      );
}

export default Carousel

 {/*
        <div className="App">

          <SlideshowContainer>
            {slides.map((e, i) => (
              <MySlide src={e} key={i} isActive={i === counter} />
            ))}
            <Prev onClick={handlePrev}>&#10094;</Prev>
            <Next onClick={handleNext}>&#10095;</Next>
          </SlideshowContainer>
    
          <DotContainer>
            {slides.map((e, i) => (
              <Dot
                isActive={i === counter}
                key={i}
                onClick={() => setCounter(i)}
              ></Dot>
            ))}
          </DotContainer>
        </div>
      */}
import React from 'react'
import { Button } from '../ButtonElements'

//import img_python from "../../images/python.png";

import { 
    InfoContainer, 
    InfoWrapper, 
    InfoRow, 
    Column1, 
    TextWrapper, 
    TopLine, 
    Heading, 
    Subtitle, 
    BtnWrap, 
    Column2,
    ImgWrap,
    Img} from './InfoElements'

const InfoSection = ({
    id,
    lightBg,
    lightText,    
    topLine,
    headline,
    description1,
    description2,
    description3,
    description4,
    buttonLabel,    
    imgStart,
    img,
    alt,    
    dark,
    dark2,
    primary,
    darkText,
    menu,
}) => {
    return (
        <>
            <InfoContainer lightBg={lightBg} id={id}>
                <InfoWrapper>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine>{topLine}</TopLine>
                                <Heading lightText={lightText}> {headline}</Heading>
                                <Subtitle darkText={darkText}> {description1}</Subtitle>
                                <Subtitle darkText={darkText}> {description2}</Subtitle>
                                <Subtitle darkText={darkText}> {description3}</Subtitle>                                
                                <Subtitle darkText={darkText}> {description4}</Subtitle>                                
                                <BtnWrap>
                                    <Button
                                    to={menu}
                                    smooth={true}
                                    duration={500}
                                    spy={true}
                                    exact='true'
                                    offset={-80}
                                    primary={primary ? 1 : 0}
                                    dark={dark ? 1 : 0}
                                    dark2={dark2 ? 1 : 0}
                                    >
                                    {buttonLabel}
                                    </Button>
                                </BtnWrap>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={img} alt={alt} />
                            </ImgWrap>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
        </>
    )
}

export default InfoSection
